exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-blockchain-tsx": () => import("./../../../src/pages/blockchain.tsx" /* webpackChunkName: "component---src-pages-blockchain-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-communication-tsx": () => import("./../../../src/pages/communication.tsx" /* webpackChunkName: "component---src-pages-communication-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-datasets-tsx": () => import("./../../../src/pages/datasets.tsx" /* webpackChunkName: "component---src-pages-datasets-tsx" */),
  "component---src-pages-elsi-tsx": () => import("./../../../src/pages/elsi.tsx" /* webpackChunkName: "component---src-pages-elsi-tsx" */),
  "component---src-pages-inclusivedesign-tsx": () => import("./../../../src/pages/inclusivedesign.tsx" /* webpackChunkName: "component---src-pages-inclusivedesign-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../../../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-mobility-tsx": () => import("./../../../src/pages/mobility.tsx" /* webpackChunkName: "component---src-pages-mobility-tsx" */),
  "component---src-pages-pending-tsx": () => import("./../../../src/pages/pending.tsx" /* webpackChunkName: "component---src-pages-pending-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-quantum-annealing-tsx": () => import("./../../../src/pages/quantum/annealing.tsx" /* webpackChunkName: "component---src-pages-quantum-annealing-tsx" */),
  "component---src-pages-quantum-computing-tsx": () => import("./../../../src/pages/quantum/computing.tsx" /* webpackChunkName: "component---src-pages-quantum-computing-tsx" */),
  "component---src-pages-quantum-internet-tsx": () => import("./../../../src/pages/quantum/internet.tsx" /* webpackChunkName: "component---src-pages-quantum-internet-tsx" */),
  "component---src-pages-quantum-tsx": () => import("./../../../src/pages/quantum.tsx" /* webpackChunkName: "component---src-pages-quantum-tsx" */),
  "component---src-pages-servicedesign-tsx": () => import("./../../../src/pages/servicedesign.tsx" /* webpackChunkName: "component---src-pages-servicedesign-tsx" */),
  "component---src-pages-transaction-proof-tsx": () => import("./../../../src/pages/transaction-proof.tsx" /* webpackChunkName: "component---src-pages-transaction-proof-tsx" */),
  "component---src-pages-vxe-event-2021-event-guideline-tsx": () => import("./../../../src/pages/vxe-event2021/event-guideline.tsx" /* webpackChunkName: "component---src-pages-vxe-event-2021-event-guideline-tsx" */),
  "component---src-pages-vxe-event-2021-tsx": () => import("./../../../src/pages/vxe-event2021.tsx" /* webpackChunkName: "component---src-pages-vxe-event-2021-tsx" */),
  "component---src-pages-vxe-tsx": () => import("./../../../src/pages/vxe.tsx" /* webpackChunkName: "component---src-pages-vxe-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-member-tsx": () => import("./../../../src/templates/member.tsx" /* webpackChunkName: "component---src-templates-member-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single-page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */)
}


// custom typefaces
import 'typeface-montserrat';
import type { GatsbyBrowser } from 'gatsby';
import { LocaleProvider, Locale } from './src/contexts/locale-context';
import React from 'react';
import { IntlProvider } from 'react-intl';
import messages from './locales';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
  const { location } = routerProps;
  const state = location && routerProps.location.state;

  // if page transition caused by language switch, will keep scroll position
  if (!state) return false;
  return !state.langSwitch;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const locale = props.pageContext.langKey as Locale;
  return (
    <LocaleProvider locale={locale}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {element}
      </IntlProvider>
    </LocaleProvider>
  );
};

import React, { createContext, ReactNode, useContext } from 'react';

export type Locale = 'ja' | 'en';

type Value = {
  locale: Locale;
};

const LocaleContext = createContext<Value>({
  locale: 'ja',
});

export const useLocale = () => useContext(LocaleContext);

type Props = { children: ReactNode; locale: Locale };

export const LocaleProvider = ({ children, locale }: Props) => {
  return <LocaleContext.Provider value={{ locale }}>{children}</LocaleContext.Provider>;
};
